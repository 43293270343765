<template>
	<div class="landing-container">
        <Transition appear name="slide-fade">
            <navigation :scrollTo="scrollTo" />
        </transition>
        <transition appear name="slide-fade-bottom">
            <HeroSection />
        </transition>
        <VideoSection />
        <FeaturesSection id="features" ref="features"/>
        <FAQSection id="faq" ref="faq"/>
        <FooterSection />
	</div>
</template>

<script>
    import FAQSection from './landing-page/FAQSection.vue'
    import FeaturesSection from './landing-page/FeaturesSection.vue'
    import FooterSection from './landing-page/FooterSection.vue'
    import HeroSection from './landing-page/HeroSection.vue'
    import Navigation from './landing-page/Navigation.vue'
    import VideoSection from './landing-page/VideoSection.vue'

	export default {
		name: 'LandingPage',
		data() {
			return {
			}
		},
		components: {
            HeroSection,
            FeaturesSection,
            VideoSection,
            FAQSection,
            FooterSection,
            Navigation
		},
        methods: {
            scrollTo(section) {
                const element = document.getElementById(section);
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
	}
</script>

<!-- Animations -->
<style>
    /*
    Enter and leave animations can use different
    durations and timing functions.
    */
    .slide-fade-enter-active {
        transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);

    }

    .slide-fade-leave-active {
        transition: all 0.3s ease-out;
    }

    .slide-fade-enter-from,
    .slide-fade-leave-to {
        transform: translateY(-30px);
        opacity: 0;
    }

    .slide-fade-bottom-enter-active {
        transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);

    }

    .slide-fade-bottom-leave-active {
        transition: all 0.3s ease-out;
    }

    .slide-fade-bottom-enter-from,
    .slide-fade-bottom-leave-to {
        transform: translateY(50px);
        opacity: 0;
    }

</style>

<style lang="scss">
    a {
        text-decoration: none;
        outline: none;
        color: black;

        &:hover {
            cursor: pointer;
        }
    }

    * {
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
        box-sizing: border-box;         /* Opera/IE 8+ */
    }

    .h1 {
        width: 100%;
        color: #fff;
        text-align: center;
        margin-top: 0;
        margin-bottom: 0;
        font-family: Inter, sans-serif;
        font-size: 48px;
        font-weight: 600;
        line-height: 125%;

        @media screen and (min-width: 1440px) {
            font-size: 48px;
        }

        @media screen and (max-width: 991px) {
            font-size: 38px;
        }

        @media screen and (max-width: 767px) {
            font-size: 36px;
        }

        @media screen and (max-width: 480px) {
            font-size: 26px;
        }
    }

    .h2 {
        width: 100%;
        color: #fff;
        text-align: left;
        margin-top: 0;
        margin-bottom: 0;
        font-family: Inter, sans-serif;
        font-size: 32px;
        font-weight: 600;
        line-height: 125%;

        @media screen and (min-width: 1440px) {
            font-size: 32px;
        }

        @media screen and (max-width: 991px) {
            font-size: 32px;
        }

        @media screen and (max-width: 767px) {
            font-size: 24px;
        }

    }

    .h3 {
        width: 100%;
        color: #fff;
        text-align: left;
        margin-top: 0;
        margin-bottom: 0;
        font-family: Inter, sans-serif;
        font-size: 24px;
        font-weight: 600;
        line-height: 125%;

        @media screen and (min-width: 1440px) {
            font-size: 24px;
        }

        @media screen and (max-width: 991px) {
            font-size: 20px;
        }
    }

    .h4 {
        width: 100%;
        color: #fff;
        text-align: left;
        margin-top: 0;
        margin-bottom: 0;
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
    }

    .b1 {
        color: rgba(255, 255, 255, .5);
        text-align: center;
        margin-bottom: 0;
        font-family: Inter, sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 150%;
        margin: 0;

        @media screen and (min-width: 1440px) {
            font-size: 20px;
        }

        @media screen and (max-width: 991px) {
            font-size: 16px;
        }

        @media screen and (max-width: 767px) {
            font-size: 14px;
        }
    }

    .b2 {
        width: 100%;
        color: rgba(255, 255, 255, .5);
        text-align: left;
        margin-top: 0;
        margin-bottom: 0;
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;

        @media screen and (max-width: 991px) {
            font-size: 14px;
        }

    }

    .b3 {
        color: rgba(255, 255, 255, .5);
        text-align: left;
        margin-top: 0;
        margin-bottom: 0;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
    }
</style>

<style scoped lang="scss">
    @import "../styles/langing.scss";

    .landing-container {
        background-color: $stone-darkest;
        flex-flow: column;
        align-items: center;
        display: block;
        width: 100%;
        height: 100%;
        overflow:scroll;

        .w-layout-blockcontainer {
            max-width: 940px;
            margin-left: auto;
            margin-right: auto;
            display: block;
        }
    }
</style>
