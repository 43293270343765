<template>
    <div class="not-found-container">
        <div class="center">
            <h1 class="h1">Page Not Found</h1>
            <p class="b1">Go to <a href="/">home</a></p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PageNotFound'
    }
</script>

<style scoped lang="scss">
    @import "../styles/langing.scss";
    .not-found-container {
        background-color: $stone-darkest;
        flex-flow: column;
        align-items: center;
        display: block;
        width: 100%;
        height: 100%;
        overflow:scroll;
        
        .center {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            min-height: 100vh;
            flex-direction: column;

            a {
                color: white;
                &:hover {
                    color: rgba(255, 255, 255, .5);
                }
            }
        }
    }
</style>