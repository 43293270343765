<template>
    <div class="keyboard">
        <div class="center-board-container">
            <CenterBoard :rows="keySet.getRows()" :spacebarText="keySet.language" :layout="layout" :keySet="keySet"/>
        </div>
    </div>
</template>

<script>
    import MultilingualKeySet from "./components/MultiLanguageKeySet";
    import SimpleKeyDefinition from "./models/SimpleKeyDefinition";
    import CenterBoard from "./components/CenterBoard.vue";
    import {sendKeyboardInput} from "./utils/sendKeyboardInput";
    import MessageType from "./models/MessageType";

    const _keySet = new MultilingualKeySet(); 

	export default {
		name: 'KeyboardComponent',
        components: {
            CenterBoard,
        },
		data() {
			return {
                keySet: _keySet,
                language: _keySet.language,
                layout: _keySet.layout,
                voiceRecognitionEnabled: false,
                voiceRecognitionActive: false,
                backspaceKeyDefinition: new SimpleKeyDefinition('Backspace', sendKeyboardInput),
                returnKeyDefinition: new SimpleKeyDefinition('Enter', sendKeyboardInput),
			}
		},
		mounted() {
			_keySet.on('layoutChanged', this._handleLayoutChange);
            if (window.vuplex) {
                this._initMessages();
            } else {
                window.addEventListener('vuplexready', this._initMessages);
            }
		},
		methods: {
            _handleLayoutChange(keySet) {
                this.language = keySet.language;
                this.layout = keySet.layout;
            },
            _initMessages() {
                window.vuplex.addEventListener('message', this._handleReceivedMessage);
                window.vuplex.postMessage({ type: MessageType.KEYBOARD_INITIALIZED });
            },
            _handleReceivedMessage(message) {
                const data = JSON.parse(message.data);

                // eslint-disable-next-line
                switch (data.type) {
                case MessageType.SET_LANGUAGE:
                    _keySet.setLanguage(data.value);
                    break;
                case MessageType.VOICE_RECOGNITION_DISABLED:
                    this.voiceRecognitionEnabled = false;
                    break;
                case MessageType.VOICE_RECOGNITION_ENABLED:
                    this.voiceRecognitionEnabled = true;
                    break;
                case MessageType.VOICE_RECOGNITION_FINISHED:
                    this.voiceRecognitionActive = false;
                    break;
                case MessageType.VOICE_RECOGNITION_STARTED:
                    this.voiceRecognitionActive = true;
                    break;
                }
            }
		},
		computed: {
		}
	}
</script>

<style>
    #app {
        margin: 0;
        padding: 0;
        font-family: Helvetica, Ariel, sans-serif;
        width: 100%;
        height: 100%;
        font-size: 20px;
    }
</style>

<style scoped lang="scss">

$keyboard-color: #323437;
$keyboard-border-radius: 0;

.keyboard {
    width: 1088px;
    height: 488px;

    font-family: 'Inter';
    font-size: 32px;


    //Disable text select
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    .num-pad-container {
        flex: 1 1 15%;
    }

    .center-board-container {
        flex: 1 1 55%;
    }

    .right-pad-container {
        flex: 1 1 15%;
    }

    .board-margin {
        flex: 1 1 2.5%
    }

    .enter-key-area {
        height: 100%;
        flex: 1 1 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .backspace-icon {
            width: 60%;
            border-radius: 0.5rem;

            &:hover {
                background-color: lighten($keyboard-color, 70%);
            }

            img {
                width: 100%;
            }
        }

        .return-key-container {
            width: 60%;
            padding-top: 10px;

            .return-key-component {
                border-radius: 50%;

                &:hover, &.hover {
                    
                }
            }

            .return-key {
                width: 100%;
                padding-top: 100%; /* 1:1 Aspect Ratio */
                position: relative;
                border-radius: 50%;
                pointer-events: none; /* For the Oculus Go hover workaround. */
            }
        }

        .voice-button-container {
            width: 60%;
            padding-top: 15px;
        }

        .return-key-text {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 45px;
            font-weight: bold;

            img {
                width: 60%;
            }
        }
    }
}

</style>
