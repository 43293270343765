import {EventEmitter} from 'events';
import KeyboardLayout from '../models/KeyboardLayout';
import ShiftKeyIcon from './ShiftKeyicon.vue';
import SimpleKeyDefinition from '../models/SimpleKeyDefinition';
import {sendKeyboardInput} from '../utils/sendKeyboardInput';
import Key from './Key.vue';

export default abstract class LanguageKeySet extends EventEmitter {

  private _layout = KeyboardLayout.LOWERCASE

  abstract get language(): string;

  abstract get languageCode(): string;

  get layout() {

    return this._layout;
  }

  protected getAlphabeticCharacterSetKeySymbol() {
    return 'ABC';
  }

  /**
  * Allows a keyset to change the currency character without having to override
  * all of the special characters
  */
  protected getCurrencyCharacters() {

    return [ '$', '€' ];
  }

  protected getLowerCaseCharactersByRow() {
    return [
      [ 'q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p' ],
      [ 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l' ],
      [ 'z', 'x', 'c', 'v', 'b', 'n', 'm' ]
    ];
  }

  protected getSpecialCharactersByRow() {
    return [
      [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
      [ '-', '/', ':', ';', '(', ')', '€', '&', '@', '"' ],
      [ '.', ',', '?', '!', '‘']
    ];
  }

  protected getNumbersByRow() {
    return [
      [ '[', ']', '{', '}', '#', '%', '^', '*', '+', '=' ],
      [ '_', '\\', '|', '~', '<', '>', '$', '£', '¥', '•' ],
      [ '.', ',', '?', '!','‘']
    ]
  }

  public getRows() {
    return this._layout === KeyboardLayout.SPECIAL ? this._getSpecialCharacterRows() : this._layout === KeyboardLayout.NUMBERS ? this._getNumbersRow()  : this._getAlphabeticCharactersRows();
  }

  protected getUpperCaseCharactersByRow() {

    return this.getLowerCaseCharactersByRow().map(row => row.map(character => character.toUpperCase()));
  }

  private _createSimpleKeyDefinition = (value:any) => new SimpleKeyDefinition(value, this._handleSimpleKeyPress);

  private _getAlphabeticCharactersRows() {

    const isUppercase = [ KeyboardLayout.UPPERCASE, KeyboardLayout.CAPS_LOCK ].includes(this._layout);

    const charactersByRow = isUppercase ? this.getUpperCaseCharactersByRow() : this.getLowerCaseCharactersByRow();
    const rows = [
      charactersByRow[0].map(this._createSimpleKeyDefinition),
      charactersByRow[1].map(this._createSimpleKeyDefinition),
      [ this._getShiftKey(), ...charactersByRow[2].map(this._createSimpleKeyDefinition), this._getBackspaceKey() ],
      [ this._getSpecialCharacterSetKey(), this._getDefaultKey(), ...[].map(this._createSimpleKeyDefinition), this._getEnterkey()]
    ];
    return rows;
  }

  private _getNumbersRow() {
    const [ firstRow, secondRow, thirdRow ] = this.getNumbersByRow();
    const rows = [
      firstRow.map(this._createSimpleKeyDefinition),
      secondRow.map(this._createSimpleKeyDefinition),
      [this._getShiftKey(), ...thirdRow.map(this._createSimpleKeyDefinition), this._getBackspaceKey()],
      [ this._getAlphabeticCharacterSetKey(), this._getDefaultKey(), this._getEnterkey() ]
    ];
    return rows;
  }

  private _getSpecialCharacterRows() {

    const [ firstRow, secondRow, thirdRow, fourthRow ] = this.getSpecialCharactersByRow();
    const rows = [
      firstRow.map(this._createSimpleKeyDefinition),
      secondRow.map(this._createSimpleKeyDefinition),
      [this._getShiftKey(), ...thirdRow.map(this._createSimpleKeyDefinition), this._getBackspaceKey()],
      [ this._getAlphabeticCharacterSetKey(), this._getDefaultKey(), this._getEnterkey() ]
    ];
    return rows;
  }

  private _getAlphabeticCharacterSetKey() {

    const symbol = this.getAlphabeticCharacterSetKeySymbol();
    return new SimpleKeyDefinition(symbol, () => this._setLayout(KeyboardLayout.LOWERCASE));
  }

  private _getShiftKey() {
    return new SimpleKeyDefinition('Shift', () => this._handleShiftKeyPressed());
  }

  private _getBackspaceKey() {
    return new SimpleKeyDefinition('Backspace', sendKeyboardInput);
  }

  private _getDefaultKey() {
    return new SimpleKeyDefinition('None', sendKeyboardInput);
  }

  private _getEnterkey() {
    return new SimpleKeyDefinition('Enter', sendKeyboardInput)
  }

  private _getSpecialCharacterSetKey() {

    return new SimpleKeyDefinition('123', () => this._setLayout(KeyboardLayout.SPECIAL));
  }

  private _handleShiftKeyPressed = () => {

    switch(this._layout) {
      case KeyboardLayout.NUMBERS:
        this._setLayout(KeyboardLayout.SPECIAL)
        break;
      case KeyboardLayout.SPECIAL: 
        this._setLayout(KeyboardLayout.NUMBERS)
        break;
      case KeyboardLayout.LOWERCASE:
        this._setLayout(KeyboardLayout.UPPERCASE);
        break;
      case KeyboardLayout.UPPERCASE:
        this._setLayout(KeyboardLayout.CAPS_LOCK);
        break;
      default:
        this._setLayout(KeyboardLayout.LOWERCASE);
    }
  };

  private _handleSimpleKeyPress = (key:any) => {

    if (this._layout === KeyboardLayout.UPPERCASE) {
      this._setLayout(KeyboardLayout.LOWERCASE);
    }

    sendKeyboardInput(key);
  }

  private _setLayout(layout: KeyboardLayout) {

    this._layout = layout;
    this.emit('layoutChanged', this);
  }
}
