import { createApp } from 'vue';
import App from './App.vue';
import router from './router/router'
import mitt from 'mitt';
import {store} from './store/store';

//Global event emitter
const emitter = mitt();

const app = createApp(App)

app.use(router)
app.use(store);

app.config.globalProperties.emitter = emitter;

app.mount('#app')
