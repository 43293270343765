<template>
    <section ref="features" id="features" class="features-section">
        <div class="features-container">
            <div class="feature spline">
                <div class="headline-image">
                    <spline-viewer id="spline-viewer" class="spline-viewer" url="https://prod.spline.design/CIyfRPeSHGxj1Jgd/scene.splinecode"></spline-viewer>
                </div>
                <div class="feature-text">
                    <h1 class="h3">Place your apps anywhere</h1>
                    <p class="b2">The spatial app lets you place your web apps all around your space. With just your Meta Quest, you have an infinite canvas to browse, work, play, and relax.</p>
                </div>
            </div>
            <div class="w-layout-layout features wf-layout-layout">
                <div class="w-layout-cell feature spline-static">
                    <img class="feature-illustration" src="../../images/landing/apps-you-love.png"/>
                    <div class="feature-text">
                        <h1 class="h3">Place your apps anywhere</h1>
                        <p class="b2">The spatial app lets you place your web apps all around your space. With just your Meta Quest, you have an infinite canvas to browse, work, play, and relax.</p>
                    </div>
                </div>
                <div class="w-layout-cell feature">
                    <img class="feature-illustration" src="../../images/landing/remote-desktop.png"/>
                    <div class="feature-text">
                        <h1 class="h3">Remote Desktop</h1>
                        <p class="b2">You can use your computer and Meta Quest simultaneously. Download the remote desktop app and stream.</p>
                        <div class="download-container">
                            <button class="download-button" @click="() => downloadDesktopCapturer()">Download Alpha for {{ this.currentSystem }}</button><label class="b3">v{{ currentVersion }}</label>
                        </div>
                    </div>
                </div>
                <div class="w-layout-cell feature">
                    <img class="feature-illustration" src="../../images/landing/discord.png"/>
                    <div class="feature-text">
                        <h1 class="h3">Coming soon...</h1>
                        <p class="b2">We are constantly updating our app based on your feedback. <a href="https://discord.gg/Nn5xy7mgX8" class="discord">Join our Discord</a> and stay in the loop.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { nextTick } from 'vue';
	export default {
        components: { 
             
        },
		name: 'FeaturesSection',
		data() {
			return {
                currentVersion:'1.0.0',
                currentSystem: null,
                winVersion: null,
                urls: {
                    mac:'https://github.com/danikincs/the-spatial-app-destkop-capture/releases/download/v1.0.0/Desktop.Capture.Installer.dmg',
                    windows64:'https://github.com/danikincs/the-spatial-app-destkop-capture/releases/download/v1.0.0/Desktop.Capture.Installer.x64.exe',
                    windows32:'https://github.com/danikincs/the-spatial-app-destkop-capture/releases/download/v1.0.0/Desktop.Capture.Installer.x32.exe'
                }
			}
		},
        mounted() {
            this.currentSystem = navigator.userAgent.includes("Windows") ? 'Windows' : 'Mac';
            if(this.currentSystem == "Windows") {
                this.winVersion = navigator.userAgent.includes("x64") ? 'x64' : 'x32'
            }

            nextTick(() =>{
                setTimeout(() => {
                    var shadowRoot = document.querySelector('spline-viewer').shadowRoot;
                    shadowRoot.querySelector('#logo').remove();
                }, 100)
            })
        },
        methods: {
            downloadDesktopCapturer() {
                this.currentSystem = navigator.userAgent.includes("Windows") ? 'Windows' : 'Mac';

                if(this.currentSystem == "Mac") {
                    window.location.href = this.urls.mac;
                }
                else {
                    if(this.winVersion === "x32") {
                        window.location.href = this.urls.windows32;
                    }
                    else {
                        window.location.href = this.urls.windows64;
                    }
                }
            },
        }
	}
</script>

<style scoped lang="scss">
    .features-section {
        flex-flow: column;
        justify-content: center;
        align-items: flex-end;
        display: flex;
        position: relative;

        @media screen and (min-width: 1440px) {
            align-items: center;
            display: flex;
        }

        @media screen and (max-width: 991px) {
            grid-column-gap: 0px;
            grid-row-gap: 0px;
        }

        .discord {
            color: #5865F2;

            &:hover {
                color: #6C79F5;

            }

        }

        .features-container {
            max-width: 1920px;
            grid-column-gap: 64px;
            grid-row-gap: 80px;
            flex-flow: column;
            justify-content: space-between;
            align-items: stretch;
            padding: 40px 15vw 130px;
            display: flex;

            @media screen and (max-width: 991px) {
                grid-column-gap: 48px;
                grid-row-gap: 48px;
                padding: 40px 100px 80px;
            }

            @media screen and (max-width: 767px) {
                grid-column-gap: 40px;
                grid-row-gap: 40px;
                padding-left: 40px;
                padding-right: 40px;
            }

            .features {
                grid-row-gap: 20px;
                grid-column-gap: 80px;
            }

            .feature {
                opacity: 1;
                transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
                transform-style: preserve-3d;
                width: 100%;
                grid-column-gap: 28px;
                grid-row-gap: 28px;
                flex-flow: column;
                display: flex;

                @media screen and (max-width: 991px) {
                    grid-column-gap: 24px;
                    grid-row-gap: 24px;
                }

                &.spline {
                    display: flex;
                    height: 100%;
                    opacity: 1;
                    @media screen and (max-width: 768px) {
                        //Spline fuck you
                        height: 0px;
                        opacity: 0;
                    }
                }

                &.spline-static {
                    display: none;

                    @media screen and (max-width: 768px) {
                        display: flex;
                    }
                }

                .headline-image {
                    width: 100%;
                    flex-flow: column;
                    justify-content: center;
                    display: flex;
                    position: relative;

                    .feature-illustration {
                        width: 100%;
                        opacity: 1;
                    }

                    img {
                        max-width: 100%;
                        vertical-align: middle;
                        display: inline-block;
                    }
                }

                .feature-text {
                    grid-column-gap: 16px;
                    grid-row-gap: 16px;
                    flex-flow: column;
                    display: flex;
                    @media screen and (max-width: 991px) {
                        grid-column-gap: 12px;
                        grid-row-gap: 12px;
                    }

                    .download-container {
                        display: flex;
                        align-items: center;
                        gap: 16px;
                    }

                    .download-button {
                        grid-column-gap: 8px;
                        grid-row-gap: 8px;
                        background-color: #fff;
                        border-radius: 999px;
                        align-items: center;
                        padding: 8px 16px 8px 14px;
                        text-decoration: none;
                        display: flex;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }

            .wf-layout-layout { 
                display: grid;
            }

            .w-layout-layout {
                grid-template-rows: auto auto;
                grid-template-columns: 1fr 1fr;

                grid-row-gap: 20px;
                grid-column-gap: 20px;
                grid-auto-columns: 1fr;
                justify-content: center;

                @media screen and (min-width: 1440px) {
                    grid-column-gap: 56px;
                    grid-row-gap: 88px;
                }

                @media screen and (max-width: 767px) {
                    grid-template-columns: unset;

                }

                .feature-illustration {
                    width: 100%;
                    opacity: 1;
                }
                img {
                    max-width: 100%;
                    vertical-align: middle;
                    display: inline-block;
                }
            }
        }
    }
</style>
