<template>
	<div class="clock-container">
        <p class="time">{{ clock }}</p>
    </div>
</template>

<script>
	import moment from "moment";

	export default {
		name: 'TallClock',
		data() {
            return {
                clock:null
            }
		},
        mounted() {
            setInterval(() => {
                this.clock = moment().format('H:mm')
            })
        },
	}
</script>

<style scoped lang="scss">
    .clock-container {
        width: 400px;
        height: 400px;

        background: var(--Stone-1000, #0C0E11);

        .time {
            margin: 0;
            font-family: "Six Caps", sans-serif;
            font-size: 380px;
            font-weight: 400;
            line-height: 380px;
            text-align: center;
            color: white;
        }
    }
</style>
