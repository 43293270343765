<template>
	<div class="timer-container">
		<div class="active-timer" v-if="!timer">
			<div class="group-style"></div>
			<div class="time">
				<div class="group">
					<VueScrollPicker 
						v-model="hour" 
						:options="[...Array(23).keys()].map(x => x.toString())"
						:dragSensitivity="0.7"
						/>
					<p>hours</p>
				</div>
				<div class="group">
					<VueScrollPicker 
						v-model="minute" 
						:options="[...Array(60).keys()].map(x => x.toString())" 
						:dragSensitivity="0.7"
					/>
					<p>min</p>
				</div>
				<div class="group">
					<VueScrollPicker 
						v-model="second" 
						:options="[...Array(60).keys()].map(x => x.toString())" 
						:dragSensitivity="0.7"
					/>
					<p>sec</p>
				</div>
			</div>
			<div class="buttons">
				<button class="" v-on:click="cancelSetTimer()" :disabled="!(hour && minute && second)"><img src="../../../images/Close.svg" /></button>
				<button class="play" v-on:click="startTimer()"><img src="../../../images/Play.svg" /></button>
			</div>
		</div>
		<div class="time-setter" v-else>
			<div class="loader">
				<div class="loader-orange" :style="{'--percentage': getPercentage}"></div>
				<div class="time">
					{{ formatedCountdown || "no countdown" }}
				</div>
			</div>
			<div class="buttons">
				<button class="" v-if="!finished" v-on:click="cancelSetTimer()"><img src="../../../images/Close.svg" /></button>
				<button class="pause" v-if="!finished" v-on:click="paused ? startTimer() : pauseTimer()">
					<img v-if="!paused" src="../../../images/Pause.svg" />
					<img v-else src="../../../images/Play.svg" />
				</button>
				<button class="" v-else @click="finishTimer()"><img src="../../../images/Close.svg" /></button>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from "moment";
	import "moment-duration-format";
	import { VueScrollPicker } from 'vue-scroll-picker'
	import "vue-scroll-picker/lib/style.css";

	let stopCountdown = null;
	let audio = new Audio('timer.mp3');

	export default {
		name: 'TimerComponent',
		data() {
			return {
				hour:0,
				minute:0,
				second:0,

				countDonwnSeconds:null,
				originalcountDownInSecods:null,
				timer: false,
				finished: false,
				paused: false,
			}
		},
		components: {
			VueScrollPicker, // export VueScrollPicker is component
		},
		methods: {
			startTimer() {
				if(!this.countDonwnSeconds) {
					this.countDonwnSeconds = parseInt(this.second) + parseInt(this.minute) * 60 + parseInt(this.hour) * 3600;
				}

				this.originalcountDownInSecods = this.countDonwnSeconds;

				stopCountdown = setInterval(() => {
					if (this.countDonwnSeconds === 0) {
						clearInterval(stopCountdown);
						this.finished = true;
						audio.loop = true;
						this.originalcountDownInSecods = null;
						audio.play();
						return;
					}
					this.countDonwnSeconds -= 1;

				}, 1000);

				this.paused = false;
				this.timer = true;
			},
			pauseTimer() {
				clearInterval(stopCountdown);
				this.paused = true;
			},
			cancelSetTimer() {
				clearInterval(stopCountdown);
				this.originalcountDownInSecods = null;
				this.countDonwnSeconds = null;
				this.timer = false;
			},
			playAudioSound() {
				audio.play();
			},
			finishTimer() {
				audio.pause();
				audio.currentTime = 0;

				clearInterval(stopCountdown);
				this.originalcountDownInSecods = null;
				this.countDonwnSeconds = null;
				this.timer = false;
			}
		},
		computed: {
			formatedCountdown() {
				return moment.duration(this.countDonwnSeconds, "seconds").format("h:mm:ss");
			},
			getPercentage() {
				let percentage = (this.countDonwnSeconds/this.originalcountDownInSecods) * 100
				return percentage + '%';
			}
		}
	}
</script>

<style src="vue-scroll-picker/lib/style.css"></style>

<style lang="scss">
	html, body {margin: 0; height: 100%; overflow: hidden}


	.vue-scroll-picker-layer-top {
		background: linear-gradient(180deg, #141414 10%, rgba(255, 255, 255, 0));
		border:none;
	}
	.vue-scroll-picker-layer-bottom {
		background: linear-gradient(180deg, rgba(255, 255, 255, 0) 10%, #141414);
		border:none
	}
	.vue-scroll-picker-item-selected {
		margin-bottom: 7px;
		color: #FFFFFF80;
		font-family: 'Inter';
	}
	.vue-scroll-picker-item {
		margin-bottom: 7px;
		color: #FFFFFF80;
		font-family: 'Inter';
	}
</style>

<style scoped lang="scss">
	.timer-container {
		background-color: #141414;
		width: 400px;
		height: 400px;

		.vue-scroll-picker {
			width: 30px;
		}

		.active-timer {
			padding: 32px;
			.group-style {
				width: 272px;
				height: 30px;
				padding: 8px;
				border-radius: 8px;
				background-color: #FFFFFF14;

				position: absolute;
				z-index: 0;
				top: 118px;
				left: 67px;


			}
			.time {
				display: flex;
				gap: 33px;
				justify-content: center;
				padding-bottom: 68px;


				.group {
					display: flex;
					align-items: center;
					gap: 0;
					p {
						font-family: 'Inter';
						font-weight: 500;
						font-size: 16px;
						line-height: 24px;
						color: white;
					}
				}
			}
		}

		.time-setter {
			padding: 32px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 50px;
			
			.loader {
				margin-top: 50px;
				//border: 4px solid white;
				border-radius: 99px;
				position: relative;
			}

			.loader-orange {
				display: block;
				position: absolute;
				left: -3px;
				top: -3px;
				right: -3px;
				bottom: -3px;
				background-color: transparent;
				background-image: conic-gradient(#FFFF00, #FFFF00 var(--percentage), transparent var(--percentage));
				border-radius: 21.5px;
				border-radius: 99px;
			}

			.time {
				font-family: 'Inter';
				font-weight: 300;
				font-size: 64px;
				line-height: 96px;
				text-align: center;
				color: white;
				padding: 36px 0px;
				z-index: 10;
				position: relative;
				background-color: #141414;
				border-radius: 99px;

			}
		}
		.buttons {
				display: flex;
				justify-content: space-between;
				gap: 20px;

				button {
					width:68px;
					height: 68px;
					border-radius: 500px;
					background-color: #FFFFFF1F;
					outline: none;
					border: none;

					display: flex;
					align-items: center;
					justify-content: center;
				}

				.disabled {
					background-color: #FFFFFF14;
				}
			}
	}
</style>
