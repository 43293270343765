<template>
	<div class="clocks" @click="changeRoute">
		<router-view v-slot="{ Component }">
			<!-- Use a custom transition or fallback to `fade` -->
			<transition mode="out-in">
				<component :is="Component" />
			</transition>
		</router-view>
	</div>
</template>

<script>
	const clocks = ["simple", "modern", "digital", "woven", "tall", "bold", "flower", "flower2", "flower3", "bridge", "bridge2", "bridge3"];

	export default {
		name: 'ClockComponent',
		data() {
			return {
				currentRouteId:0
			}
		},
        mounted() {
        },
		methods: {
			changeRoute() {
				if(this.currentRouteId + 1 > clocks.length) {
					this.currentRouteId = 0;
				}
				else {
					this.currentRouteId += 1;
				}

				if(clocks[this.currentRouteId]) {
					this.$router.push('/clock/' + clocks[this.currentRouteId]);
				}
			}
		},
	}
</script>

<style lang="scss">
	.v-enter-active,
	.v-leave-active {
		transition-property: opacity;
		transition-duration: .3s;
	}

	.v-enter-from,
	.v-leave-to {
		opacity: 0;
	}
</style>
