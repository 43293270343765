<template>
    <section id="FAQs" class="faqs-section">
        <div class="faqs-container">
            <div class="horizontal-divider"></div>
            <div class="w-layout-layout faq-wrapper wf-layout-layout">
                <div class="w-layout-cell faq">
                    <h2 class="h2">FAQs</h2>
                    <p class="b2">Have questions or problems? You might find them here.</p>
                </div>
                <div class="w-layout-cell faq-items">
                    <FAQItem v-bind:key="item.question" v-for="item in faqQuestions" :question="item.question" :answer="item.answer"/> 
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import FAQItem from './components/FAQItem.vue'

    const faqQuestions = [
        {question: 'How do I sign in with Google?', answer: 'Because Quest is on Android, some services require sign in on the website’s mobile site. To toggle between desktop and mobile versions you need to go to Settings and find the option to toggle it to mobile. After you sign in you can toggle it back to the desktop version.'},
        //{question: 'Is there a light mode?', answer: 'Currently there is no switch between dark and light mode.'},
        {question: 'Can I add more apps to the app drawer?', answer: 'We are planning to add support for more apps in the future but for now all currently visible apps are the only ones optimized.'},
    ];

	export default {
        components: {
            FAQItem
        },
		name: 'FAQSection',
		data() {
			return {
                faqQuestions: faqQuestions
			}
		},
	}
</script>

<style lang="scss" scoped>
    @import "../../styles/langing.scss";

    .faqs-section {
        grid-column-gap: 64px;
        grid-row-gap: 64px;
        flex-flow: column;
        justify-content: center;
        align-self: stretch;
        align-items: center;
        display: flex;
        position: relative;

        .faqs-container {
            width: 100%;
            max-width: 1920px;
            grid-column-gap: 40px;
            grid-row-gap: 40px;
            flex-flow: column;
            justify-content: space-between;
            align-items: flex-start;
            padding-bottom: 150px;
            padding-left: 15vw;
            padding-right: 15vw;
            display: flex;

            @media screen and (max-width: 767px) {
                padding-bottom: 80px;
                padding-left: 40px;
                padding-right: 40px;
            }
        }

        @media screen and (min-width: 1440px) {
            grid-column-gap: 88px;
            grid-row-gap: 88px;
            padding-top: 0;
            padding-bottom: 150px;
        }

        .horizontal-divider {
            width: 100%;
            height: 1px;
            background-color: $stone-900;

            @media screen and (max-width: 991px) {
                display: none;
            }
        }

        .wf-layout-layout {
            display: grid;
        }

        .w-layout-layout {
            grid-row-gap: 20px;
            grid-column-gap: 20px;
            grid-auto-columns: 1fr;
            justify-content: center;
            padding: 20px;
        }

        .faq-wrapper {
            grid-template-rows: auto;
            grid-template-columns: .5fr 1fr;

            width: 100%;
            min-width: 100%;
            grid-column-gap: 40px;
            grid-row-gap: 64px;
            padding: 0;

            @media screen and (min-width: 1440px) {
                grid-column-gap: 56px;
                grid-row-gap: 88px;
            }

            @media screen and (max-width: 767px) {
                grid-template-rows: auto auto;
                grid-template-columns: 1fr;
            }

            .w-layout-cell {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                display: flex;
            }

            .faq {
                text-align: left;
                grid-column-gap: 16px;
                grid-row-gap: 16px;

            }

            .faq-items {
                grid-column-gap: 8px;
                grid-row-gap: 8px;
            }
        }
    }
</style>
