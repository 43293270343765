<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
  body {
		background-color: #141414;
  }
</style>
