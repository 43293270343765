import KeyDefinition from '../models/KeyDefinition';
import MessageType from '../models/MessageType';
import {store} from '../../../store/store'; // Adjust the path according to your project structure

declare global {
    interface Window {
        vuplex:any;
    }
}

export function sendKeyboardInput(keyDefinition: KeyDefinition, isSuggestion? : boolean, lastWord? : String) {
    const value = keyDefinition && keyDefinition.value ? keyDefinition.value : null;

    store.dispatch('updateWrittenText', {value: value, isSuggestion: isSuggestion ? true : false});

    if (window && window.vuplex) {

        if(lastWord) {
            const actualLastWordList = lastWord.split(" ");
            const actualLastWord = actualLastWordList[actualLastWordList.length-1];
    
            if(actualLastWord) {
                const howManyBackSpace = actualLastWord?.split('');
                for (const backspace of howManyBackSpace) {
                    window.vuplex.postMessage({ type: MessageType.INPUT_RECEIVED, value: 'Backspace' });
                }
            }
        }
    

        window.vuplex.postMessage({ type: MessageType.INPUT_RECEIVED, value: value });
    }
}