// store.js
import {createStore} from 'vuex';

/* eslint-disable */
export const store = createStore({
  state: {
    writtenText: ""
  },
  mutations: {
    setWrittenText(state, text) {
        state.writtenText = state.writtenText + text;
    },
    clearWrittenText(state) {
        state.writtenText = ''
    },
    removeLastElementWrittenText(state) {
        state.writtenText = state.writtenText.slice(0, -1);
    },
    overwriteLastWord(state, text) {
        let words = state.writtenText.split(' '); 
        words[words.length - 1] = text;
        let wordsString = words.join(' ');
        state.writtenText = wordsString;
    }
  },
  actions: {
    updateWrittenText({ commit }, data) {
        if(data.value === 'Enter') {
            commit('clearWrittenText');
        }
        else if(data.value === 'Backspace') {
            commit('removeLastElementWrittenText')
        }
        else {
            if(data.value !== "None") {
                if(data.isSuggestion) {
                    console.log('clicked on suggestion');
                    if(!['is', 'a', 'the'].includes(data.isSuggestion)) {
                        commit('overwriteLastWord', data.value)
                    }
                    else {
                        commit('setWrittenText', data.value);
                    }
                }
                else {
                    commit('setWrittenText', data.value);
                }
            }
        }
    },
    clearWrittenText() {
        commit('setWrittenText', '')
    }
  }
});