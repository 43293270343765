import KeyDefinition from './KeyDefinition';
import { VueElement } from 'vue';

export default class SimpleKeyDefinition implements KeyDefinition {

    constructor(public value: string|VueElement, private _clickHandler: (arg0:SimpleKeyDefinition) => void) {}

    onClick = () => {
        if (this._clickHandler) {
            this._clickHandler(this);
        }
    }
}