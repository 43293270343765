<template>
	<div class="calculator-container">
        <div class="result-value-container">
            <button class="extra" @click="() => clearEverything()">{{'C'}}</button>
            <div id="result" class="result" :style="{fontSize: calculatedFont}">{{ currentDisplayValue }}</div>
        </div>
        <div class="buttons">
            <!--
            <div class="row">
                <button class="extra" @click="() => this.previousNumber ? clearCurrent() : clearEverything()">{{this.previousNumber ? 'C' : 'AC'}}</button>
                <button class="extra" @click="() => changeMinus()">+/-</button>
                <button class="extra" @click="() => convert()">%</button>
            </div>
            -->
            <div class="row">
                <button class="number" @click="() => numberClick(7)">7</button>
                <button class="number" @click="() => numberClick(8)">8</button>
                <button class="number" @click="() => numberClick(9)">9</button>
                <button :class="['operation', {'highlighted': isNullAndHavePreviousOperation('/')}]" @click="() => addOperation('/')"><img src="./icons/division.svg"></button>
            </div>
            <div class="row">
                <button class="number" @click="() => numberClick(4)">4</button>
                <button class="number" @click="() => numberClick(5)">5</button>
                <button class="number" @click="() => numberClick(6)">6</button>
                <button :class="['operation', {'highlighted': isNullAndHavePreviousOperation('*')}]" @click="() => addOperation('*')"><img src="./icons/multiplication.svg"></button>

            </div>
            <div class="row">
                <button class="number" @click="() => numberClick(1)">1</button>
                <button class="number" @click="() => numberClick(2)">2</button>
                <button class="number" @click="() => numberClick(3)">3</button>
                <button :class="['operation', {'highlighted': isNullAndHavePreviousOperation('-')}]" @click="() => addOperation('-')"><img src="./icons/extraction.svg"></button>
            </div>
            <div class="row">
                <button class="number" @click="() => numberClick('.')">.</button>
                <button class="number" @click="() => numberClick(0)">0</button>
                <button :class="['operation']" @click="() => displayResult()"><img src="./icons/result.svg"></button>
                <button :class="['operation', {'highlighted': isNullAndHavePreviousOperation('+')}]" @click="() => addOperation('+')"><img src="./icons/addition.svg"></button>
            </div>
        </div>
    </div>
</template>

<script>
    import {evaluate} from 'mathjs';

	export default {
		name: 'CalculatorComponent',
		data() {
			return {
                currentDisplayValue: '0',
                activeOperation: null,
                previousNumber: null,
                fullMathString:'',
			}
		},
		components: {
			
		},
		methods: {
            numberClick(number) {
                if(!this.activeOperation) {
                    if(this.currentDisplayValue === '0' || this.isResult) {
                        this.currentDisplayValue = number.toString();
                        this.isResult = false;
                    }
                    else {
                        if(this.currentDisplayValue.length < 17) {
                            this.currentDisplayValue += number.toString();
                        }
                    }
                }else {
                    this.previousNumber = this.currentDisplayValue;
                    this.currentDisplayValue = number.toString();
                    this.fullMathString += this.previousNumber + this.activeOperation;
                    this.activeOperation = null;
                }
            },
            addOperation(operation) {
                this.isResult = false;
                this.activeOperation = operation
            },
            displayResult() {
                this.fullMathString += this.currentDisplayValue;
                
                this.currentDisplayValue = this.processValue(evaluate(this.fullMathString)).toString();
                this.fullMathString = '';
                this.activeOperation = null;
                this.isResult = true;
            },
            processValue(value) {
                // Check if the value is a number and if it is a float by comparing it to its integer version
                if (typeof value === 'number' && value !== Math.floor(value)) {
                    return value.toFixed(2);
                } else {
                    return value;
                }
            },
            clearEverything() {
                this.previousNumber = null;
                this.currentDisplayValue = '0';
                this.fullMathString = '';
                this.activeOperation = null;
            },
            changeMinus() {
                this.isResult = false;
                let tempStringArray = Array.from(this.currentDisplayValue);
                if(tempStringArray[0] === '-') {
                    tempStringArray.shift()
                    this.currentDisplayValue = tempStringArray.join().replaceAll(',', '');
                }
                else {
                    tempStringArray.splice(0, 0, "-");
                    this.currentDisplayValue = tempStringArray.join().replaceAll(',', '');
                }
            },
            isNullAndHavePreviousOperation(active) {
                return this.activeOperation && this.activeOperation === active
            },
            convert() {
                let value = parseFloat(this.currentDisplayValue) / 100;
                this.currentDisplayValue = value.toString();
            }
		},
        computed: {
            calculatedFont() {
                let length = this.currentDisplayValue.length
                if(length < 7) {
                    return '68px'
                }
                if(length > 13) {
                    return '24px'
                }

                if(length > 10) {
                    return '32px'
                }
                if(length > 8) {
                    return '42px'
                }
                if(length >= 7) {
                    return '52px'
                }
                return '68px'
            },
        }
	}
</script>

<style scoped lang="scss">
	.calculator-container {
		background-color: #141414;
		width: 400px;
		height: 504px;
        padding: 32px;

        display: flex;
        flex-direction: column;
        gap: 32px;

        .result-value-container {
            padding-bottom: 0;
            position: relative;

            display: flex;
            justify-content: space-between;

            .extra {
                width: 72px;
                height: 72px;
                outline: none;
                border: none;
                border-radius: 99px;
                background-color: #FFFFFF14;

                color: white;
                font-family: Inter, sans-serif;
                font-weight: 500;
                font-size: 32px;

                &:hover {
                    cursor: pointer;
                    background-color: #ffffff2a;
                }

            }


            .result {
                color: white;
                font-family: 'Inter';
                font-weight: 300;
                font-size: calc(10vw + 1vh);
                line-height: 68px;
                //line-break: anywhere;
                max-width: 400px;

            }
        }

        .buttons {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .row {
                display: flex;
                gap: 16px;
            }

            button {
                width: 72px;
                height: 72px;
                border-radius: 99px;

                font-family: Inter, sans-serif;
                font-weight: 500;
                font-size: 32px;
                line-height: 32px;
                color: white;

                outline: none;
                border: none;

                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    cursor: pointer;
                }

                &.double {
                    width: 172px;
                }

                &.number {
                    box-shadow: 0px 2px 2px 0px #FFFFFF5C inset, 0px -2px 3px 0px #000000CC inset;

                    background: var(--Stone-800, #282A2D);
                    background-blend-mode: lighten;

                    &:hover {
                        background-color: #ffffff2a;
                    }
                }

                &.operation {
					background-color: #ff9d0ade;
                    box-shadow: 0px 2px 2px 0px #FFFFFF42 inset, 0px -2px 3px 0px #000000CC inset;

                    &:hover {
                        background-color: #ff9d0a;
                    }

                    &.highlighted {
                        background-color: #ffa827de;
                    }
                }

                &.extra {
                    color: black;
                    background-color: #ffffff9c;
                    &:hover {
                        background-color: #ffffffb8;
                    }
                }
            }
        }
    }
</style>
