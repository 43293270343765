<template>
	<div class="clock-container">
		<img src="./watchfaces/woven-watchface.png" />
		<img class="hour" :style="{rotate: hour + 'deg'}" src="./indicators/hour-indicator.svg"/>
		<img class="minute" :style="{rotate: minute + 'deg'}" src="./indicators/minute-indicator.svg"/>
		<img class="second" :style="{rotate: second + 'deg'}" src="./indicators/second-indicator-white.svg"/>
	</div>
</template>

<script>

	export default {
		name: 'SimpleClock',
		data() {
			return {
				hour:0,
				minute:0,
				second:0
			}
		},
        mounted() {
            setInterval(this.updateClock)
        },
		methods: {
			updateClock() {
				let date = new Date()
				let sec = date.getSeconds()

				let min = date.getMinutes() + sec/60;

				let hr = date.getHours() + min/60; // you could also add + sec/3600 but that would barely make any difference


				this.hour = hr * 30;
				this.minute = min * 6;
				this.second = sec * 6;

			}
		}
	}
</script>

<style scoped lang="scss">
	.clock-container {
		width: 400px;
		height: 400px;
		gap: 8px;
		background: var(--Stone-1000, #0C0E11);
		position: relative;

		.hour, .minute, .second {
			margin: auto;
			position: absolute;
			top: 0; left: 0; bottom: 0; right: 0;
		}
		
	}
</style>
