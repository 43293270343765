<template>
    <section id="footer" class="footer-section">
        <div class="footer-container">
            <div class="footer-disclaimer">
                <img src="../../images/landing/spatial-logo.svg" />
                <p class="b3">
                    Brought to you by two guys who wanted to learn something new and have fun.
                </p>
            </div>
            <div class="social">
                <p class="b3 social">
                    <a href="/privacy-policy" class="social-item">Privacy Policy</a>
                </p>
                <p class="b3 social">
                    <a href="https://discord.gg/Nn5xy7mgX8" class="social-item">Discord</a>
                </p>
                <p class="b3 social">
                    <a href="https://twitter.com/thespatialapp" class="social-item">Twitter</a>
                </p>
                <p class="b3 social">
                    <a href="https://www.buymeacoffee.com/thespatialapp" class="social-item">Buy us a coffee</a>
                </p>
            </div>
        </div>
    </section>
</template>

<script>
	export default {
        components: { 
             
        },
		name: 'FooterSection',
		data() {
			return {
			}
		},
	}
</script>

<style scoped lang="scss">
    @import "../../styles/langing.scss";

    .footer-section {
        grid-column-gap: 64px;
        grid-row-gap: 64px;
        background-color: $stone-1000;
        flex-flow: row;
        justify-content: center;
        align-self: stretch;
        align-items: flex-start;
        padding-top: 80px;
        padding-bottom: 80px;
        display: flex;
        position: static;

        @media screen and (min-width: 1440px) {
            grid-column-gap: 88px;
            grid-row-gap: 88px;
            padding-top: 120px;
            padding-bottom: 120px;
        }

        .footer-container {
            width: 100%;
            max-width: 1920px;
            justify-content: space-between;
            align-items: flex-start;
            padding-left: 15vw;
            padding-right: 15vw;
            display: flex;
            @media screen and (max-width: 991px) {
                padding-left: 100px;
                padding-right: 100px;
            }

            @media screen and (max-width: 767px) {
                grid-column-gap: 40px;
                grid-row-gap: 40px;
                flex-flow: column;
                padding-left: 40px;
                padding-right: 40px;
            }

            @media screen and (max-width: 479px){
                padding-left: 24px;
                padding-right: 24px;
            }

            .footer-disclaimer {
                width: 430px;
                grid-column-gap: 24px;
                grid-row-gap: 24px;
                flex-flow: column;
                align-items: flex-start;
                display: flex;

                @media screen and (max-width: 991px) {
                    width: 270px;
                    grid-column-gap: 16px;
                    grid-row-gap: 16px;
                }

                img {
                    height: 40px;
                }
            }

            .social {
                grid-column-gap: 16px;
                grid-row-gap: 16px;
                flex-flow: column;
                display: flex;

                a {
                    color: rgba(255, 255, 255, .5);
                    text-decoration: none;
                }
            }
        }
    }

</style>