<template>
    <div class="landing-container">
        <div class="w-layout-blockcontainer">
            <div class="title-container">
                <h2 class="h2">FAQs</h2>
                <div class="buttons">
                    <button @click="() => openPage('https://www.buymeacoffee.com/thespatialapp')">Buy us a coffee</button>
                    <button @click="() => openPage('https://discord.gg/Nn5xy7mgX8')">Discord group</button>
                    <button @click="() => openPage('https://twitter.com/thespatialapp')">Twitter</button>
                </div>
            </div>
            <div class="faq-container">
                <div>
                    <h2 class="h4">How do I sign in with Google?</h2>
                    <br/>
                    <p class="b2">
                        Because Meta Quest is on Android, some services require sign in on the website’s mobile site. We optimized the app to easily sign in with Google, but if you still cannot sign in, there is a troubleshoot.
                        <br/><br/>To toggle between desktop and mobile versions you need to go to Settings and find the option to toggle it to mobile. After you sign in you can toggle it back to the desktop version.
                    </p>
                    <br/>
                </div>
                <div>
                    <h2 class="h4">Do you keep my sign in information anywhere?</h2>
                    <br/>
                    <p class="b2">No. We don’t story any of your information anywhere.</p>
                    <br/>
                </div>
                <div>
                    <h2 class="h4">Can I add more apps to the app library?</h2>
                    <br/>
                    <p class="b2">We are planning to add support for more apps in the future. Join our <a class="discord" href="https://discord.gg/Nn5xy7mgX8">Discord</a> to choose which ones get added next.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    const faqQuestions = [
        {question: 'How do I sign in with Google?', answer: 'Because Quest is on Android, some services require sign in on the website’s mobile site. To toggle between desktop and mobile versions you need to go to Settings and find the option to toggle it to mobile. After you sign in you can toggle it back to the desktop version.'},
        //{question: 'Is there a light mode?', answer: 'Currently there is no switch between dark and light mode.'},
        {question: 'Can I add more apps to the app drawer?', answer: 'We are planning to add support for more apps in the future but for now all currently visible apps are the only ones optimized.'},
    ];

	export default {
        components: {
        },
		name: 'FAQPage',
		data() {
			return {
                faqQuestions: faqQuestions
			}
		},
        methods: {
            openPage(page) {
                window.location.replace(page)
            }
        }
	}
</script>

<style lang="scss" scoped>

    @import "../styles/langing.scss";
    .landing-container {
        background-color: $stone-darkest;
        flex-flow: column;
        align-items: center;
        display: block;
        width: 100%;
        height: 100%;
        overflow:scroll;

        .w-layout-blockcontainer {
            margin-top: 72px;
            max-width: 940px;
            margin-left: auto;
            margin-right: auto;
            display: block;

            .title-container {
                display: flex;
                flex-direction: column;
                gap: 16px;

                margin-bottom: 40px;

                .buttons {
                    display: flex;
                    gap:8px;

                    button {
                        color: white;
                        background-color: #FFFFFF24;
                        box-shadow: none;
                        border: none;
                        padding: 8px 12px;
                        border-radius: 999px;

                        font-family: Inter, sans-serif;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 18px;

                        &:hover {
                            cursor:pointer;
                            background-color: #ffffff4a;
                        }
                    }
                }
            }


            .discord {
                color: #5865F2;

                &:hover {
                    color: #6C79F5;

                }

            }

        }
    }

</style>
