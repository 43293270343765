<template>
	<div class="clock-container">
        <img class="background" rel="preload" src="./watchfaces/digital-watchface.png" />
        <div class="group">
            <p class="hour">{{ hour }}</p>
            <p class="minute">{{ minute }}</p>
        </div>
        <div class="foreground-group">
            <img class="foreground" rel="preload" src="./foreground/digital-foreground.png" />
            <img class="foreground" rel="preload" src="./foreground/digital-foreground.png" />
        </div>
    </div>
</template>

<script>
	import moment from "moment";

	export default {
		name: 'TallClock',
		data() {
            return {
                hour:null,
                minute:null
            }
		},
        mounted() {
            moment().format('H:mm:ss')
            setInterval(() => {
                this.hour = moment().format('H');
                this.minute = moment().format('mm');
            })
        },
		components: {
			
		},
		methods: {

		},
		computed: {

		}
	}
</script>

<style scoped lang="scss">
    .clock-container {
        width: 400px;
        height: 400px;

        background: var(--Stone-1000, #0C0E11);
        padding: 65px 20px;
        position:relative;

        .background {
            position:absolute;
        }

        .group {
            display: flex;
            flex-direction: row;
            gap: 50px;
            padding-top: 64px;
            position: relative;
            z-index: 1;
            align-items: center;
            justify-content: center;
        }

        .foreground-group {
            top: 190px;
            left: 54px;
            display: flex;
            flex-direction: row;
            gap: 42px;
            position: absolute;
            z-index: 1;
        }

        .hour {
            margin: 0;
            color: white;
            font-family: Bebas Neue;
            font-size: 151px;
            font-weight: 400;
            line-height: 151px;
            letter-spacing: -0.02em;
            text-align: center;

        }

        .minute {
            margin: 0;
            font-family: Bebas Neue;
            font-size: 151px;
            font-weight: 400;
            line-height: 151px;
            letter-spacing: -0.02em;
            text-align: center;
            color: white;
        }
    }
</style>
