<template>
	<div class="note-container" :style="{backgroundColor: backgroundColor}" @mouseover="showButton = true" @mouseleave="showButton = false; colorPickerOpen=false">
        <textarea
            :style="{backgroundColor: backgroundColor}"
            @input="updateValue($event.target.value)"
            @focus="showButton = false; colorPickerOpen=false"
            v-model="textValue"
            placeholder="Start jotting away..."
        >
        </textarea>
        <transition>
            <div class="color-picker" v-if="showButton">
            <div class="color-picker-button" @click="colorPickerOpen = !colorPickerOpen">
                <img v-if="!colorPickerOpen" src="./images/open.svg"/>
                <img v-else src="./images/close.svg" />
            </div>
            <transition>
                <div v-if="colorPickerOpen" class="colors">
                    <div v-bind:key="color" @click="() => changeColor(color)" class="color" v-for="color in colors" :style="{backgroundColor:color}"></div>
                </div>
            </transition>
        </div>
        </transition>
	</div>
</template>

<script>

	export default {
		name: 'NoteComponent',
		data() {
			return {
                backgroundColor: '#FFFFFF',
                colors: ['#FFFFFF', '#FFF59B', '#FF9E9E', '#B5E0FF', '#9CFFB2', '#E3A7FF'],
                colorPickerOpen:false,
                textValue:null,
                showButton:false
			}
		},
		components: {

		},
		methods: {
            changeColor(color) {
                this.backgroundColor = color;
            },
            updateValue(value) {
                this.textValue = value
            },
		},
	}
</script>

<style scoped lang="scss">
    .note-container {
        width: 400px;
        height: 400px;

        padding: 32px;

        display: flex;
        flex-direction: column;
        gap: 18px;

        textarea {
            width: 336px;
            height: 274px;

            resize: none;
            border: none;
            outline: none;

            font-family: Kalam;
            font-size: 26px;
            font-weight: 400;
            line-height: 39px;
            text-align: left;

        }

        .color-picker {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;

            .color-picker-button {
                height: 44px;
            }

            .colors {
                display: flex;
                flex-direction: row;
                gap: 4px;

                background-color: #0C0E11;
                border-radius: 999px;
                padding: 8px;

                .color {
                    width: 28px;
                    height: 28px;
                    border-radius: 999px;

                    &:hover {
                        cursor: pointer;
                    }

                }

            }

        }

    }
</style>
