<template>
    <section id="privacy-policy-container" class="privacy-policy-container">
        <Transition appear name="slide-fade">
            <navigation :scrollTo="scrollTo" />
        </Transition>
        <div class="privacy-policy-contanet">
            <div class="header-title">
                <h2 class="h1">Privacy Policy</h2>
                <p class="b2">Last updated: Mar 26, 2024</p>
            </div>
            <div class="body-container">
                <div>
                    <p class="title h4">tl;dr</p>
                    <br />
                    <span class="contnet b2">Welcome to the spatial app! Our service is designed to function fully without storing or tracking user data. As such, there are no accounts, log-ins, or personal data inputs required or stored in our services.</span>
                </div>
                <br />
                <div>
                    <p class="title h4">Information We Do Not Collect</p>
                    <br />
                    <span class="contne b2">Our services are designed with your privacy in mind. As such, we do not track or store any personal data, including but not limited to your geographical location, device identifiers, account details or any form of personal identifiers. Our app is built to provide services without the need to collect, use, or disclose personally identifying information from its users. Your login details for other services like Google, Disney+, AppleTV+, and others are locally stored on your device and are not available to us.</span>
                </div>
                <br />
                <div>
                    <p class="title h4">Data Security</p>
                    <br />
                    <span class="contne b2">Even though we do not collect personal information, we take the security of our app and its infrastructure seriously. We implement various measures to protect the security of any incidental data and ensure the integrity of our service.</span>
                </div>
                <br />
                <div>
                    <p class="title h4">Changes to Our Privacy Policy</p>
                    <br />
                    <span class="contnet b2">It is our policy to post any changes we make to our privacy policy on this page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for periodically visiting our app and this privacy policy to check for any changes.</span>
                </div>
                <br />
                <div>
                    <p class="title h4">Contact Information</p>
                    <br />
                    <span class="contnet b2">To ask questions or comment about this privacy policy and our privacy practices, contact us at: thespatialapp@gmail.com</span>
                </div>
            </div>
        </div>
        <FooterSection />
    </section>
</template>

<script>
    import FooterSection from './landing-page/FooterSection.vue'
    import Navigation from './landing-page/Navigation.vue';

    export default {
        components: { 
            FooterSection,
            Navigation 
        },
        name:'PrivacyPolicy'
    }
</script>

<style lang="scss" scoped>
    @import "../styles/langing.scss";

    .privacy-policy-container {
        background-color: $stone-darkest;
        flex-flow: column;
        align-items: center;
        display: block;
        width: 100%;
        height: 100%;
        overflow:scroll;

        .privacy-policy-contanet {
            max-width: 918px;
            margin: auto;
            padding-top: 150px;
            padding-bottom: 150px;

            @media screen and (max-width: 991px) {
                max-width: 792px;
                margin: auto;
                padding-top: 150px;
                padding-bottom: 150px;
            }

            @media screen and (max-width: 767px) {
                max-width: 568px;
                margin: auto;
                padding-top: 150px;
                padding-bottom: 150px;
            }

            @media screen and (max-width: 479px) {
                max-width: unset;
                width: 100%;
                padding-top: 137px;
                padding-left: 40px;
                padding-right: 40px;
                padding-bottom: 120xpx;
            }

            @media screen and (max-width: 321px) {
                max-width: unset;
                width: 100%;
                padding-top: 137px;
                padding-left: 24px;
                padding-right: 24px;
                padding-bottom: 120xpx;
            }

            .header-title {
                margin-bottom: 40px;
                .h1 {
                    margin-bottom: 18px;
                }
            }

            .body-container {
                div {

                }
            }
            
        }
    }
</style>