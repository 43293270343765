<template>
	<div class="clock-container">
        <p class="hour">{{ hour }}</p>
        <p class="minute">{{ minute }}</p>
    </div>
</template>

<script>
	import moment from "moment";

	export default {
		name: 'TallClock',
		data() {
            return {
                hour:null,
                minute:null
            }
		},
        mounted() {
            moment().format('H:mm:ss')
            setInterval(() => {
                this.hour = moment().format('H');
                this.minute = moment().format('mm');
            })
        },
		components: {
			
		},
		methods: {

		},
		computed: {

		}
	}
</script>

<style scoped lang="scss">
    .clock-container {
        width: 400px;
        height: 400px;

        background: var(--Stone-1000, #0C0E11);
        padding: 65px 40px;

        .hour {
            margin: 0;
            font-family: "Climate Crisis", sans-serif;
            font-size: 150px;
            font-weight: 400;
            line-height: 135px;
            text-align: center;
            color: white;
        }

        .minute {
            margin: 0;
            font-family: "Climate Crisis", sans-serif;
            font-size: 150px;
            font-weight: 400;
            line-height: 135px;
            text-align: center;
            color: white;
        }
    }
</style>
