<template>
    <section id="video" class="video-section">
    </section>
</template>

<script>
	export default {
        components: { 
             
        },
		name: 'VideoSection',
		data() {
			return {
			}
		},
	}
</script>
