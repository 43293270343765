<template>
    <a class="button---navigation w-inline-block" href="https://www.meta.com/experiences/7486934774707264/?utm_source=oculus&utm_medium=share">
        <img class="meta-logo" src="../../../images/landing/meta-logo.svg" loading="lazy"/>
        <div class="button-label">Get now</div>
    </a>
</template>

<script>

	export default {
		name: 'HeroSection',
		data() {
			return {
                price:3500
			}
		},
	}
</script>

<style scoped lang="scss">
    .button---navigation {
        grid-column-gap: 8px;
        grid-row-gap: 8px;
        background-color: #fff;
        border-radius: 999px;
        align-items: center;
        padding: 8px 16px 8px 14px;
        text-decoration: none;
        display: flex;

        &:hover {
            background-color: #eceef1;
        }

        img {
            max-width: 100%;
            vertical-align: middle;
            display: inline-block;
        }

        .button-label {
            color: #000;
            font-family: Inter, sans-serif;
            font-size: 16px;
            font-weight: 600;
            line-height: 150%;
            text-decoration: none;
        }
    }
</style>