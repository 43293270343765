<template>
	<div class="clock-container">
        <img class="background" src="./background/flower-2-background.png" />
        <div class="group">
            <p class="date">{{ date }}</p>
            <p class="clock">{{ clock }}</p>
        </div>
        <img class="foreground" src="./foreground/flower-2-foreground.png" />
    </div>
</template>

<script>
	import moment from "moment";

	export default {
		name: 'FlowerClock',
		data() {
            return {
                clock:null,
                date:null
            }
		},
        mounted() {
            setInterval(() => {
                this.clock = moment().format('H:mm')
                this.date = moment().format("MMM Do YY"); 

            })
        },
	}
</script>

<style scoped lang="scss">
    .clock-container {
        width: 400px;
        height: 400px;

        background: var(--Stone-1000, #0C0E11);
        position:relative;

        .background {
            position:absolute;
            bottom: 0;
        }

        .foreground {
            position:absolute;
            bottom: 0;
            z-index: 2;

        }

        .group {
            display: flex;
            flex-direction: column;
            gap: 0px;
            padding-top: 64px;
            z-index: 1;
            position: relative;
        }

        .clock {
            margin: 0;
            font-family: Inter;
            font-size: 120px;
            font-weight: 500;
            line-height: 120px;
            letter-spacing: -0.05em;
            text-align: center;
            color: white;
        }

        .date {
            margin: 0;
            color: white;
            font-family: Inter;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: -0.02em;
            text-align: center;
        }
    }
</style>
