<template>
    <div :class="[classes, {'key-down': isKeyDown, 'key-up': isKeyUp}, valueClass]" @mousedown="_handleMouseDown" @mouseup="_handleMouseUp" @mouseleave="_handleMouseLeave" >
        <template v-if="!$slots.data"><span :class="valueClass">{{definition.value}}</span></template>
        <template v-else>
            <slot name="data"></slot>
        </template>
    </div>
</template>

<script>

    let _keyUpTimeoutId;
    let _keyDownTimeoutId;
    let _keyDownContinuouslyIntervalId;

    const keyStateEnum = {
        DOWN:0,
        DOWN_CONTINUOUSLY:1,
        UP:2,
        NORMAL:3
    }


    export default {
        name: 'KeyComponent',
        props: ['className', 'definition'],
        data() {
            return {
                keyState: keyStateEnum.DOWN,
                classes: []
            }
        },
        mounted() {
            this.classes = [ 'key-component' ];

            if (this.className) {
                this.classes.push(this.className);
            }
        },
        computed: {
            isKeyDown() {
                return this.keyState === keyStateEnum.DOWN || this.keyState === keyStateEnum.DOWN_CONTINUOUSLY
            },
            isKeyUp() {
                return this.keyState === keyStateEnum.UP
            },
            valueClass() {
                let posFixLeftBottom = [')'];
                let posFixRightBottom = ['('];
                let posFixBottomMinor = ['#', '%']
                let posFix = [ '[', ']', '{', '}', '@', '<', '>', '+', '•', '=']
                if(!this.$slots.data && this.definition && this.definition.value) {
                
                    if(posFix.includes(this.definition.value)) {
                        return 'fix-position'
                    }

                    if(posFixLeftBottom.includes(this.definition.value)) {
                        return 'fix-position-left-bottom'
                    }

                    if(posFixRightBottom.includes(this.definition.value)) {
                        return 'fix-position-right-bottom'
                    }

                    if(posFixBottomMinor.includes(this.definition.value)) {
                        return 'fix-position-bottom-minor'
                    }
                }
                return ''
            }
        },
        methods: {
            clearTimers() {
                clearTimeout(_keyUpTimeoutId);
                clearTimeout(_keyDownTimeoutId);
                clearInterval(_keyDownContinuouslyIntervalId);
            },
            _handleMouseDown() {
                this.clearTimers();
                this.keyState = keyStateEnum.DOWN;

                this.definition.onClick();
                // After the key is continously held down for a second, start triggering the key every 100 ms.
                _keyDownTimeoutId = setTimeout(() => {
                    this.keyState = keyStateEnum.DOWN_CONTINUOUSLY;
                    _keyDownContinuouslyIntervalId = setInterval(this.definition.onClick, 100);
                }, 1000);
            },
            _handleMouseLeave() {
                if (this.keyState === keyStateEnum.DOWN || this.keyState === keyStateEnum.DOWN_CONTINUOUSLY) {
                    this.clearTimers();
                    this.keyState = keyStateEnum.NORMAL;
                }
            },
            _handleMouseUp() {
                this.clearTimers();
                this.keyState = keyStateEnum.UP;
                _keyUpTimeoutId = setTimeout(() => {
                    this.keyState = keyStateEnum.NORMAL
                }, 1000);
            }
        }
    }
</script>

<style lang="scss">

    $keyboard-color: #323437;
    $keyboard-border-radius: 0;

    .key-component {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.5rem;

        background-color: #484A4D;
        box-shadow: 0px 2px 2px 0px #FFFFFF5C inset, 0px -2px 2px 0px #000000CC inset;

        img {
            width: 48px;
            //height: 48px;
        }

        > img, div, span {
            pointer-events: none; /* For the Oculus Go hover workaround. */
        }

        &:hover, &.hover {
              background-color: lighten($keyboard-color, 15%);
          }

        &.key-up {
            animation: highlight 1s;
        }

        @keyframes highlight {
            0% {
                background-color: lighten($keyboard-color, 50%);
            }
            100% {
                background-color: auto;
            }
        }

        &.fix-position {
            span {
                margin-bottom: 0.3rem;
            }
        }

        &.fix-position-left-bottom {
            span {
                margin-bottom: 0.2rem;
                margin-left: 0.3rem;
            }
        }

        &.fix-position-right-bottom {
            span {
                margin-bottom: 0.2rem;
                margin-right: 0.3rem;
            }
        }

        &.fix-position-bottom-minor {
            span {
                margin-bottom: 0.1rem;
            }
        }

    }
</style>