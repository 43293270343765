<template>
    <section id="navigation" class="navigation" @click="() => $router.push('/')">
        <div class="w-layout-blockcontainer navigation-container w-container">
            <div class="left-side">
                <img class="spatialos-logo" src="../../images/landing/spatial-logo.svg" loading="lazy"/>
            </div>
            <div class="right-side">
                <a @click="() => scrollToElement('faq')">
                    <div class="navigation-item">FAQs</div>
                </a>
                <ButtonComponent />
            </div>
        </div>
    </section>
</template>

<script>
    import ButtonComponent from './components/ButtonComponent.vue';

    export default {
        name: 'NavigationComponent',
        props: ['scrollTo'],
        components:{
            ButtonComponent
        },
        methods: {
            scrollToElement(element) {
                if(this.scrollTo) {
                    this.scrollTo(element)
                }
                else {
                    this.$router.push('/')
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .navigation {
        z-index: 1000;
        flex-flow: column;
        align-self: stretch;
        align-items: center;
        padding-top: 24px;
        padding-left: 24px;
        padding-right: 24px;
        display: block;
        position: fixed;
        top: 0%;
        bottom: auto;
        left: 0%;
        right: 0%;

        &:hover {
            cursor: pointer;
        }

        @media screen and (max-width: 767px) {
            padding-top: 8px;
            padding-left: 8px;
            padding-right: 8px;
        }

        .w-container {
            &:before {
                content: " ";
                grid-area: 1 / 1 / 2 / 2;
                display: table;
            }

            &:after {
                content: " ";
                grid-area: 1 / 1 / 2 / 2;
                display: table;
            }
        }

        .navigation-container {
            width: 100%;
            max-width: 1872px;
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            -webkit-backdrop-filter: blur(64px);
            backdrop-filter: blur(64px);
            outline-offset: -1px;
            background-color: rgba(12, 14, 17, .8);
            border-radius: 999px;
            outline: 1px solid rgba(255, 255, 255, .04);
            flex-flow: row;
            justify-content: flex-start;
            align-items: center;
            padding: 12px 12px 12px 22px;
            display: flex;
            margin: auto;

            @media screen and (max-width: 767px) {
                padding: 8px 8px 8px 14px;
            }

            .left-side {
                width: 30%;
                height: 40px;
                justify-content: flex-start;
                align-items: center;
                display: flex;
            }

            .right-side {
                width: 70%;
                height: 40px;
                grid-column-gap: 40px;
                grid-row-gap: 40px;
                justify-content: flex-end;
                align-items: center;
                display: flex;
            }

            .navigation-item {
                color: rgba(255, 255, 255, .5);
                font-family: Inter, sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 150%;
                text-decoration: none;
                @media screen and (max-width: 767px) {
                    display: none;
                }

                &:hover {
                    color: rgba(255, 255, 255, .7);
                }
            }

        }
}
</style>