<template>
    <div class="keyboard">
        <div class="active-text">
            <div>
                <p>{{ writtenText }}</p>
            </div>
        </div>
        <div class="suggestions">

            <template v-if="suggestions.length">
                <div v-bind:key="suggestion" v-for="suggestion in suggestions" class="suggestion" @click="() => sendWord(suggestion)">
                    {{ suggestion }}
                </div>
            </template>
            <template v-else>
                <div class="suggestion" @click="() => sendWord('is')">
                    is
                </div>
                <div class="suggestion" @click="() => sendWord('a')">
                    a
                </div>
                <div class="suggestion" @click="() => sendWord('the')">
                    the
                </div>
            </template>
        </div>
        <div class="center-board-container">
            <CenterBoard :rows="keySet.getRows()" :spacebarText="keySet.language" :layout="layout" :keySet="keySet"/>
        </div>
    </div>
</template>

<script>
    import MultilingualKeySet from "./components/MultiLanguageKeySet";
    import SimpleKeyDefinition from "./models/SimpleKeyDefinition";
    import CenterBoard from "./components/CenterBoard.vue";
    import {sendKeyboardInput} from "./utils/sendKeyboardInput";
    import MessageType from "./models/MessageType";
    import axios from 'axios';

    const _keySet = new MultilingualKeySet(); 

	export default {
		name: 'KeyboardComponent',
        components: {
            CenterBoard,
        },
		data() {
			return {
                keySet: _keySet,
                language: _keySet.language,
                layout: _keySet.layout,
                voiceRecognitionEnabled: false,
                voiceRecognitionActive: false,
                backspaceKeyDefinition: new SimpleKeyDefinition('Backspace', sendKeyboardInput),
                returnKeyDefinition: new SimpleKeyDefinition('Enter', sendKeyboardInput),
                suggestions: []
			}
		},
		mounted() {
			_keySet.on('layoutChanged', this._handleLayoutChange);
            if (window.vuplex) {
                this._initMessages();
            } else {
                window.addEventListener('vuplexready', this._initMessages);
            }
		},
        watch: {
            writtenText(next) {

                let lastWordList = next.split(' ');
                let lastWord = lastWordList[lastWordList.length-1];
                const isLastSpace = next[next.length - 1] === ' ' || next === '';

                const isEmail = next[next.length - 1] === '@';
                const isWebsite = next[next.length - 1] === '.';

                console.log('isLastSpace')

                if(isEmail) {
                    this.suggestions = ['gmail.com', 'icloud.com', 'outlook.com'];
                }
                else if(isWebsite) {
                    this.suggestions = ['com', 'co.uk', 'net'];
                }
                else if(isLastSpace) {
                    this.suggestions = [];
                }
                else {
                    this.checkSuggestion(lastWord);
                }
            }
        },
		methods: {
            _handleLayoutChange(keySet) {
                this.language = keySet.language;
                this.layout = keySet.layout;
            },
            _initMessages() {
                window.vuplex.postMessage({ type: MessageType.KEYBOARD_INITIALIZED });
            },
            _handleReceivedMessage(message) {
                const data = JSON.parse(message.data);

                // eslint-disable-next-line
                switch (data.type) {
                case MessageType.SET_LANGUAGE:
                    _keySet.setLanguage(data.value);
                    break;
                case MessageType.VOICE_RECOGNITION_DISABLED:
                    this.voiceRecognitionEnabled = false;
                    break;
                case MessageType.VOICE_RECOGNITION_ENABLED:
                    this.voiceRecognitionEnabled = true;
                    break;
                case MessageType.VOICE_RECOGNITION_FINISHED:
                    this.voiceRecognitionActive = false;
                    break;
                case MessageType.VOICE_RECOGNITION_STARTED:
                    this.voiceRecognitionActive = true;
                    break;
                }
            },
            sendWord(word) {
                const emails = ['gmail.com', 'icloud.com', 'outlook.com'];
                const websites = ['com', 'co.uk', 'net']

                if([...emails, ...websites].includes(word)) {
                    sendKeyboardInput(new SimpleKeyDefinition(word + " ", null), true)
                }
                else {
                    console.log('clicking word so I sending')
                    sendKeyboardInput(new SimpleKeyDefinition(word + " ", null), true, this.$store.state.writtenText)
                }
            },
            async checkSuggestion(word) {
                try {
                    let res = await axios.get('https://api.datamuse.com/sug?s=' + word);
                    if(res.data.length) {
                        this.suggestions = [res.data[0].word, res.data[1].word, res.data[2].word]
                    }
                }
                catch(err) {
                    console.log('error', err)
                }
            }
		},
        computed: {
            writtenText() {
                return this.$store.state.writtenText;
            }
        }
	}
</script>

<style>
    #app {
        margin: 0;
        padding: 0;
        font-family: Helvetica, Ariel, sans-serif;
        width: 100%;
        height: 100%;
        font-size: 20px;
    }
</style>

<style scoped lang="scss">

    $keyboard-color: #323437;
    $keyboard-border-radius: 0;

    .keyboard {
        width: 1088px;
        height: 692px;

        font-family: 'Inter';
        font-size: 32px;


        //Disable text select
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */

        .active-text {
            padding:47px;
            background-color: #222427;
            color: white;
            font-size: 28px;
            line-height: 150%;
            overflow:auto;
            height: 136px;

            div {
                height: 36px;
            }

            p {
                margin: 0;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        .suggestions {
            background-color: $keyboard-color;
            padding: 32px;
            padding-bottom: 0;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 8px;

            .suggestion {
                text-align: center;
                background-color: #484A4D;
                border-radius: 100px;
                width: 100%;
                padding: 16px;

                color: white;
                font-size: 28px;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .num-pad-container {
            flex: 1 1 15%;
        }

        .center-board-container {
            flex: 1 1 55%;
        }

        .right-pad-container {
            flex: 1 1 15%;
        }

        .board-margin {
            flex: 1 1 2.5%
        }

        .enter-key-area {
            height: 100%;
            flex: 1 1 10%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .backspace-icon {
                width: 60%;
                border-radius: 0.5rem;

                &:hover {
                    background-color: lighten($keyboard-color, 70%);
                }

                img {
                    width: 100%;
                }
            }

            .return-key-container {
                width: 60%;
                padding-top: 10px;

                .return-key-component {
                    border-radius: 50%;

                    &:hover, &.hover {
                        
                    }
                }

                .return-key {
                    width: 100%;
                    padding-top: 100%; /* 1:1 Aspect Ratio */
                    position: relative;
                    border-radius: 50%;
                    pointer-events: none; /* For the Oculus Go hover workaround. */
                }
            }

            .voice-button-container {
                width: 60%;
                padding-top: 15px;
            }

            .return-key-text {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 45px;
                font-weight: bold;

                img {
                    width: 60%;
                }
            }
        }
    }

</style>
