<template>
    <section id="hero" class="hero-section">
        <div class="hero-wrapper">
            <div class="hero-text">
                <div class="early-access">
                    <div class="label">
                        Early access
                    </div>
                </div>
                <div class="price-text">
                    <h1 class="h1">Experience spatial computing for $                    
                        <Transition name="slide-fade-price" mode="out-in">
                            <span class="price" :key="price">{{ price }}</span>
                        </Transition>
                    </h1>
                </div>
                <p class="b1">A passion project made for people who want to dive into the future of computing. Available in the Meta Quest store.</p>
            </div>
            <ButtonComponent />
        </div>
    </section>
</template>

<script>
import ButtonComponent from "./components/ButtonComponent.vue"


	export default {
        components: { 
            ButtonComponent 
        },
		name: 'HeroSection',
		data() {
			return {
                price:3500,
                updatePrice:false,
			}
		},
        mounted() {
            setTimeout(() => {
                this.price = 10;
                this.updatePrice = true;
            }, 2000)
        }
	}
</script>

<style>
    .slide-fade-price-enter-active {
        transition: all .3s ease;
    }

    .slide-fade-price-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fade-price-enter,
    .slide-fade-price-leave-to {
        transform: translateX(10px);
        opacity: 0;
    }
</style>

<style lang="scss" scoped>
    .hero-section {
        height: auto;
        background-color: rgba(0, 0, 0, 0);
        justify-content: center;
        padding: 208px 100px 120px;
        display: flex;

        @media screen and (max-width: 991px) {
            padding-top: 168px;
            padding-bottom: 80px;
        }

        @media screen and (max-width: 767px) {
            padding-top: 137px;
            padding-left: 40px;
            padding-right: 40px;
        }

        @media screen and (max-width: 479px) {
            padding-left: 24px;
            padding-right: 24px;
        }

        .hero-wrapper {
            width: 580px;
            grid-column-gap: 56px;
            grid-row-gap: 56px;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            display: flex;

            opacity: 1;
            transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
            transform-style: preserve-3d;

            @media screen and (max-width: 991px) {
                width: 412px;
                grid-column-gap: 40px;
                grid-row-gap: 40px;
            }

            @media screen and (max-width: 479px) {
                width: 272px;
                grid-column-gap: 24px;
                grid-row-gap: 24px;
            }

            .hero-text {
                width: 100%;
                grid-column-gap: 24px;
                grid-row-gap: 24px;
                flex-flow: column;
                align-items: center;
                display: flex;

                @media screen and (max-width: 991px) {
                    grid-column-gap: 16px;
                    grid-row-gap: 16px;
                }

                .early-access {
                    background-color: rgba(255, 172, 43, .14);
                    border-radius: 999px;
                    padding: 8px 12px;

                    .label {
                        color: #ffac2b;
                        font-family: Inter, sans-serif;
                        font-size: 12px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
</style>