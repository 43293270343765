<template>
	<div class="timer-container">
		<div class="active-timer">
			<div class="time">
				<p>{{ timer }}</p>
			</div>
			<div class="buttons">
				<button :disabled="!started" @click="cancelStopwatch()"><img rel="preload" src="../../../images/Close.svg" /></button>
				<button v-if="!started || paused" class="play" @click="startStopwatch()"><img rel="preload" src="../../../images/Play.svg" /></button>
				<button v-else class="play" @click="pauseStopwatch()"><img rel="preload" src="../../../images/Pause.svg" /></button>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from "moment";
	import "moment-duration-format";

	let interval = null;

	export default {
		name: 'StopwatchComponent',
		data() {
			return {
				timer: moment().hour(0).minute(0).second(0).format('HH:mm:ss'),
				paused: false,
				started:false,
				counter:0
			}
		},
		components: {

		},
		methods: {
			startStopwatch() {
				console.log('what hello');
				this.started = true;
				this.paused = false;
				interval = setInterval(() => {
					this.timer = moment().hour(0).minute(0).second(this.counter++).format('HH:mm:ss');
				}, 1000);
			},
			pauseStopwatch() {
				this.paused = true;
				clearInterval(interval);

			},
			cancelStopwatch() {
				console.log('cancel')
				clearInterval(interval);
				this.started = false;
				this.counter = 0;
				this.timer = moment().hour(0).minute(0).second(0).format('HH:mm:ss');
			},

			finishTimer() {

			}
		},
		computed: {

		}
	}
</script>


<style scoped lang="scss">
	.timer-container {
		background-color: #141414;
		width: 400px;
		height: 400px;

		.vue-scroll-picker {
			width: 30px;
		}

		.active-timer {
			display: flex;
			flex-direction: column;
			gap: 84px;

			.time {
				font-family: Inter;
				font-size: 64px;
				font-weight: 300;
				line-height: 96px;
				text-align: center;
				margin-top: 120px;

				p {
					color: white;
					margin: 0;
				}

			}
		}

		.buttons {
				display: flex;
				justify-content: space-between;
				gap: 20px;
				padding: 0px 32px 32px 32px;

				button {
					width:68px;
					height: 68px;
					border-radius: 500px;
					background-color: #FFFFFF1F;
					outline: none;
					border: none;
					display: flex;
					align-items: center;
					justify-content: center;
					color: white;

					img {
						width: 31px;
						height: 31px;
					}

					&:hover {
						cursor: pointer;
					}

					&:disabled {
						background-color: #FFFFFF14;

						&:hover {
							cursor: default;
						}
					}
				}

			}
	}
</style>
