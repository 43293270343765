<template>
     <div class="faq-item">
        <div class="question"  @click="show = !show">
            <p class="b1 question">{{ question }}</p>
            <img :class="{flipped:show}" src="../../../images/landing/chevron.svg" alt="chevron"/>
        </div>
        <div v-if="show" class="answer">
            <p class="b2 answer">{{ answer }}</p>
        </div>
    </div>
</template>

<script>
	export default {
		name: 'FAQItem',
        props: ['question', 'answer'],
        data() {
            return {
                show: false
            }
        }
	}
</script>

<style scoped lang="scss">
    @import "../../../styles/langing.scss";

    .faq-item {
        width: 100%;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        background-color: $stone-1000;
        flex-flow: column;
        padding-bottom: 0;
        display: flex;
        overflow: hidden;

        .question {
            grid-column-gap: 24px;
            grid-row-gap: 24px;
            justify-content: space-between;
            align-items: center;
            padding: 24px 32px 24px;
            display: flex;

            .b1 {
                color: #fff;
                text-align: left;
                padding: 0;
                font-weight: 500;
            }

            &:hover {
                cursor: pointer;
            }

            .chevron {
                width: 24px;
                height: 24px;
            }

            img {
                max-width: 100%;
                vertical-align: middle;
                display: inline-block;

                &.flipped {
                    rotate: 180deg;
                }
            }
        }

        .answer {
            opacity: 1;
            padding: 8px 32px 24px;

            .answer {
                opacity: 1;
                width: 100%;
                margin-top: 0;
                padding: 0;
            }
        }
    }
</style>
