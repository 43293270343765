<template>
    <div class="center-board">
        <div class="key-area" :class="getLayoutClass">
            <div class="key-row row-1">
                <Key v-for="key in rows[0]" class="key" :definition="key" :key="key.value"/>
            </div>
            <div class="key-row row-2">
                    <Key v-for="key in rows[1]" class="key" :definition="key" :key="key.value"/>
            </div>
            <div class="key-row row-3">
                <Key v-for="key in rows[2].slice(0, 1)" class="key black shift" :definition="key" :key="key.value">
                    <template #data>
                        <img v-if="layout === 0" src="./icons/shift.svg" />
                        <img v-else-if="layout === 1" src="./icons/shift-active.svg" />
                        <img v-else-if="layout === 2" src="./icons/shift-caps.svg" />
                        <span v-else-if="layout === 3">#+=</span>
                        <span v-else>123</span>
                    </template>
                </Key>
                <Key v-for="key in rows[2].slice(1, -1)" class="row-3-key key" :definition="key" :key="key.value">
                </Key>
                <Key v-for="key in rows[2].slice(-1)" class="key black back" :definition="key" :key="key.value">
                    <template #data>
                        <img src="./icons/backspace.svg" />
                    </template>
                </Key>

            </div>
            <div class="key-row row-4 space-enter-row">
                <Key v-for="key in rows[3].slice(0, 1)" class="row-4-key key black special" :definition="key" :key="key.value"/>
                <Key v-for="key in rows[3].slice(1, 2)" class="row-4-key key black language" :definition="key" :key="key.value">
                    <template #data>
                        <img src="./icons/language.svg" />
                    </template>
                </Key>

                <div class="spacebar-container">
                    <Key className="spacebar" :definition="spacebarKeyDefinition">
                    </Key>
                </div>
                <div class="enter-container">
                    <Key className="enter" :definition="enterKeyDefinition">
                        <template #data>
                            Enter
                        </template>
                    </Key>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SimpleKeyDefinition from '../models/SimpleKeyDefinition';
    import {sendKeyboardInput} from "../utils/sendKeyboardInput";
    import Key from "../components/Key.vue";

    const _spacebarKeyDefinition = new SimpleKeyDefinition(' ', sendKeyboardInput);
    const _enterKeyDefinition = new SimpleKeyDefinition('Enter', sendKeyboardInput);
    const _backspaceKeyDefinition = new SimpleKeyDefinition('Backspace', sendKeyboardInput);

    export default {
		name: 'CenterBoard',
        props: ['rows', 'spacebarText', 'layout', 'keySet'],
        components: {
            Key
        },
        data() {
            return {
                spacebarKeyDefinition:_spacebarKeyDefinition,
                enterKeyDefinition: _enterKeyDefinition,
                backspaceKeyDefinition: _backspaceKeyDefinition,
            }
        },
        computed: {
            getLayoutClass() {
                switch (this.layout) {
                    case 0:
                        return 'lowercase'
                    case 1:
                        return 'uppercase'
                    case 2:
                        return 'caps-lock'
                    case 3:
                        return 'special'
                    case 4:
                        return 'numbers'
                }
                return 'default-layout'
            }
        }
    }
</script>

<style lang="scss">
$keyboard-color: #323437;
$keyboard-border-radius: 0;

.center-board {
    background-color: $keyboard-color;
    color: white;
    height: 100%;
    width: 100%;
    border-radius: $keyboard-border-radius;
    padding: 32px;


    .key-area {
        display: flex;
        flex-direction: column;
        gap: 24px;

        &.special, &.numbers {
            .row-3-key{
                &:not(.black) {
                    width: 126px !important;

                }
            }
        }

        &.lowercase {
            .row-1, .row-2, .row-3 {
                .key {
                    &:not(.black)  {
                    span {
                        margin-top: -0.3rem;
                    }
                }
                }
            }
        }

        &.lowercase, &.uppercase, &.caps-lock {
            .row-2 {
                margin-right: 52px;
                margin-left: 52px;
            }
        }

        .key-row {
            display: flex;
            justify-content: space-between;
            gap: 16px;

            &.space-enter-row {
                gap: 16px;
            }


            .key {
                width: 88px;
                height: 88px;
                border-radius: 100px;

                &.black {
                    background-color: #222427;
                }

                &.shift {
                    margin-right: 24px;
                    width: 116px;
                }

                &.special {
                    width: 144px;
                }

                &.language {
                    width: 112px;
                }

                &.back {
                    margin-left: 24px;
                    width: 116px;
                }

            }
            
            .enter-container {

                .enter {
                    width: 254px;
                    height: 88px;
                    border-radius: 100px;

                    background-color: #222427;
                }
            }

            .spacebar-container {
                flex: 12 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;

                .spacebar {
                    border-radius: 100px;
                    width: 100%;
                    height: 88px;

                    &.hover, &:hover {
                        background-color: lighten($keyboard-color, 15%);
                    }

                    &:active {
                        background-color: lighten($keyboard-color, 30%);
                    }
                }
            }
        }
    }
}
</style>
